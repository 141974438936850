import React from "react";
import { useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import TriangularTriangle from "../../../static/images/Triangular-Triangle.webp";
import { BookMeeting } from "../../components/BookMeeting";
import { Container } from "../../components/Container";

const secrets = css`
  ${tw`my-8`}
  h2 {
    ${tw`text-5xl font-black`}
  }
  img {
    ${tw`fill-current mx-auto w-9/12 h-3/4 mt-8`}
  }
`;

const UnlockSecretsBlock: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();
  const servicesIntroductionContent = t("index.introduction.content").split("\n");

  return (
    <Container {...rest}>
      <section>
        <article css={secrets}>
          <h2>{t("index.introduction.header")}</h2>
          <img src={TriangularTriangle} alt={t("index.introduction.triangularTriangleAlt")} />
          {servicesIntroductionContent.map((servicesIntroduction, id) => (
            <p tw="max-w-prose mx-auto my-6 text-base sm:text-xl" key={id}>
              {servicesIntroduction}
            </p>
          ))}
          <BookMeeting commentFirst={true} />
        </article>
      </section>
    </Container>
  );
};

export default UnlockSecretsBlock;
